const Amex = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="28"
      viewBox="0 0 44 28"
      fill="none"
      {...props}
    >
      <path
        d="M40.5266 1.1582C41.8003 1.1582 42.8424 2.20031 42.8424 3.47399V24.3161C42.8424 25.5898 41.8003 26.6319 40.5266 26.6319H3.47399C2.20031 26.6319 1.1582 25.5898 1.1582 24.3161V3.47399C1.1582 2.20031 2.20031 1.1582 3.47399 1.1582H40.5266Z"
        fill="#006FCF"
      />
      <path
        d="M10.3868 11.8898L11.283 14.062H9.49749L10.3868 11.8898ZM28.9999 11.9801H25.5529V12.9377H28.9443V14.3723H25.5598V15.4399H29.0069V16.2956L31.4118 13.6961L29.0069 10.9866L28.9999 11.9801ZM12.7164 9.27062H17.3422L18.3693 11.5111L19.3211 9.26367H31.3284L32.5766 10.6416L33.8677 9.26367H39.3828L35.3081 13.7239L39.3411 18.1563H33.7438L32.4956 16.7784L31.193 18.1563H11.613L11.041 16.7784H9.73254L9.15939 18.1563H4.63086L8.4357 9.26367H12.4073L12.7164 9.27062ZM22.7473 10.5188H20.1548L18.4179 14.6131L16.5363 10.5188H13.9635V16.0883L11.5782 10.5188H9.27054L6.51244 16.9011H8.31296L8.88496 15.5233H11.8909L12.4629 16.9011H15.6123V12.3448L17.6398 16.9081H19.0177L21.0324 12.3587V16.9093H22.7206L22.7484 10.5177L22.7473 10.5188ZM33.562 13.7239L36.4926 10.5188H34.383L32.5292 12.5174L30.7368 10.5188H23.9121V16.9081H30.6395L32.5083 14.8957L34.3008 16.9081H36.4718L33.5632 13.7239H33.562Z"
        fill="white"
      />
    </svg>
  )
}

export default Amex
